import React from "react";
import ForgotPasswordLayer from "../components/ForgotPasswordLayer";


const ForgotPasswordPage = () => {
  return (
    <>

      {/* ForgotPasswordLayer */}
      <ForgotPasswordLayer />

    </>
  );
};

export default ForgotPasswordPage;
